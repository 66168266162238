<template>
    <div>
        <custom-vue-table class="pl-3 pr-3" title="Product" :fields="fields" :url="listUrl" :per-page="10" ref="table"
                          :extra-params="{ brand, attribute_family, category }">
            <template #filters>
                <div class="btn-group ml-3">
                    <drop-btn text="Filters" icon="fa fa-filter" size="md" dropdown-width="30r">
                        <div class="p-3">
                            <validated-ajax-vue-select name="Brand" :url="brandOptions"
                                                       label="Brand"
                                                       v-model="brand"/>
                            <validated-ajax-vue-select name="Category" :url="categoryOptions"
                                                       label="Category"
                                                       v-model="category"/>
                            <validated-ajax-vue-select name="Attribute" :url="familyOptions"
                                                       label="Attribute"
                                                       v-model="attribute_family"/>
                        </div>
                    </drop-btn>
                    <btn icon="fa fa-plus" size="md" @click="$refs.addModal.show()" text="Add"/>
                </div>
            </template>
            <template #actions="{rowData}">
                <div class="btn-group-tight">
                    <delete-btn @click="setDelete(rowData)" size="xs" width="10r"/>
                    <btn color="primary" size="xs" @click="viewDetails(rowData)" text="View"></btn>
                </div>
            </template>
        </custom-vue-table>
        <modal title="Add Product" ref="addModal" no-close-on-backdrop width="30r">
            <b-form :save-url="addUrl" @success="formSuccess" v-slot="{model, loading}">
                <validated-input placeholder="Product Name" name="Name" v-model="model.name" :disabled="loading"
                                 :rules="rules.name"/>
                <validated-input placeholder="SKU" name="Sku" v-model="model.sku" :disabled="loading"
                                 :rules="rules.sku"/>
                <validated-ajax-vue-select :url="familyOptions" placeholder="Attribute Family"
                                           :rules="rules.attribute_family"
                                           v-model="model.attribute_family" :disabled="loading"/>
                <btn size="xs" text="Save" :loading="loading" loading-text="Saving..."></btn>
            </b-form>
        </modal>
        <delete-modal ref="deleteModal" :url="deleteUrl" :params="deletingItem" @response="deleteComplete">
            <p>You are about to delete the category <b v-html="deletingItem && deletingItem.name"></b>. Are you sure
                ?</p>

            <template #loading>
                <delete-animation/>
                Please wait while we delete <b v-html="deletingItem && deletingItem.name"></b>.
            </template>
        </delete-modal>
    </div>
</template>

<script>
import urls from '@/data/urls';

export default {
    name : 'CatalogueList',
    data () {
        return {
            brand            : '',
            category         : '',
            attribute_family : '',
            listUrl          : urls.catalogueAdmin.catalogue.list,
            addUrl           : urls.catalogueAdmin.catalogue.add,
            deleteUrl        : urls.catalogueAdmin.catalogue.delete,
            familyOptions    : urls.catalogueAdmin.catalogue.familyOptions,
            brandOptions     : urls.catalogueAdmin.catalogue.brandOptions,
            categoryOptions  : urls.catalogueAdmin.catalogue.categoryOptions,
            deletingItem     : null,
            fields           : [
                {
                    name      : 'sku',
                    sortField : 'sku',
                    title     : 'SKU'
                },
                {
                    name      : 'name',
                    sortField : 'name',
                    title     : 'Name'
                },
                {
                    name      : 'family',
                    sortField : 'family',
                    title     : 'Attribute Family'
                },
                {
                    name       : '__slot:actions',
                    title      : '',
                    titleClass : 'center aligned text-right',
                    dataClass  : 'aligned text-right'
                }

            ],
            rules : {
                sku              : { required : true },
                attribute_family : { required : true },
                name             : { required : true }
            }
        };
    },
    methods : {
        viewDetails (rawData) {
            this.$router.push('/catalogue/' + rawData.id + '/details/');
        },
        formSuccess () {
            const refs = this.$refs;
            refs.addModal.close();
            refs.table.refreshTable();
        },
        setDelete (item) {
            this.deletingItem = { ...item };
            this.$refs.deleteModal.show();
        },

        deleteComplete () {
            this.deletingItem = null;
            this.$refs.deleteModal.close();
            this.$refs.table.refreshTable();
        }
    }
};
</script>

<style scoped>

</style>
